import { FC } from 'react';
import styles from '../Title.module.scss';

const CommentsTitle: FC = () => {

  return (
		<div className={styles.name_page}>
		  <div className={styles.wrapper}>
		  	<h1 className={styles.title}>Reviews <span className={styles.red}>About <br/> Our</span> Company</h1>
		  	<div className={styles.line}></div>
		  	<a className={styles.link} href="#aboutComments">Explore more</a>
		  </div>
    </div>
  );
};

export default CommentsTitle;

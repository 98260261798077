import { FC } from 'react';
import Contacts from '../../components/Contacts/Contacts';
import PageLayout from '../../components/PageLayout/PageLayoat';
import TheProjects from '../../components/TheProjects/TheProjects';
import CommentsTitle from '../../components/Title/TitleComments/TitleComments';
import YourComments from '../../components/YourComments/YourComments';

const Comments: FC = () => {
  return (
    <PageLayout>
      <CommentsTitle />
      <YourComments />
      <TheProjects />
      <Contacts />
    </PageLayout>
  );
};

export default Comments;

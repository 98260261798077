import { FC } from 'react';
import { useSelector } from 'react-redux';
import styles from '../About/About.module.scss';
import style from './YourComments.module.scss';

const YourComments: FC = () => {
	
  return (
    <div className={styles.about} id="aboutComments">
	  	<div className={styles.wrapper}>
				<div className={styles.container}>
					<h2 className={styles.title}>
						Your <span className={styles.color_red}>Comments</span>
					</h2>
					<div className={style.cont}></div>
				</div>
	  	</div>
  	</div>
  );
};

export default YourComments;

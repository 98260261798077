import { FC } from 'react';

const NotFound: FC = () => {
  return (
    <div>
      NotFound
    </div>
  );
};

export default NotFound;
